<template>
  <div class="module-unit">
    <vxe-grid
      ref="refTable"
      v-bind="tableOptions"
      :data="selfList"
      @checkbox-change="selectChangeEvent"
    >
      <template #default_gong_shi="{ row }">
        {{ row.gong_shi ? row.gong_shi.map((row) => row.name).join('') : '无' }}
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfList: [],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        'checkbox-config': { checkStrictly: true },
        columns: [
          {
            title: '单位名称',
            field: 'unit_name',
            width: 201
          },
          { title: 'id', field: 'id', width: 101 },
          {
            title: '计算公式',
            width: 302,
            slots: { default: 'default_gong_shi' }
          },
          { title: '操作', type: 'checkbox', align: 'right' }
        ]
      }
    }
  },
  watch: {
    list: {
      handler(val) {
        if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
          this.selfList = this.$util.deepCopy(val)
          this.$nextTick(() => {
            this.selfList.forEach((item) => {
              this.$refs.refTable.setCheckboxRow([item], item.is_head) // 设置复选框选中状态
            })
          })
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 组-单位-删除
    async groupUnitDelete(index) {
      let { id } = this.selfList[index]
      let isFail = false
      if (id) {
        await this.$api({
          method: 'get',
          url: '/admin/gcvip/columnDataJiSuanGongShi/delete',
          params: {
            id
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('删除成功')
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      this.selfList.splice(index, 1)
    },
    // 复选框更新
    selectChangeEvent(val) {
      let { data, rowIndex } = val
      let obj = data[rowIndex]
      obj.is_head = !obj.is_head // 切换选中值

      let params = {
        column_data_id: obj.column_data_id,
        gong_shi_id: obj.id,
        is_head: obj.is_head
      }
      this.$api({
        method: 'get',
        url: '/admin/gcvip/columnDataJiSuanGongShi/setOpenHead',
        params
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('保存成功')
          this.$emit('success')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.module-unit {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    margin-bottom: 5px;
  }
}
</style>
