<template>
  <div class="box-dialog">
    <el-dialog
      width="400px"
      title="批量引用同级类目商品属性"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form ref="refForm" label-width="100px">
        <el-form-item label="当前材质:">
          <el-input v-model="categoryActiveName" size="mini" disabled />
        </el-form-item>
        <el-form-item label="引用材质:">
          <el-select size="mini" :style="{ width: '100%' }" v-model="categoryImportId">
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item.key"
              :value="item.val"
            />
          </el-select>
          <vxe-grid v-bind="tableOptions" ref="refTable" :data="tableList" class="m-t-10" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      categoryActiveName: '',
      categoryImportId: '',
      categoryList: [],
      tableOptions: {
        ref: 'refTable',
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          {
            type: 'checkbox',
            width: 40
          },
          {
            title: '商品属性名称',
            field: 'cai_zhi'
          }
        ]
      },
      tableList: []
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    },
    categoryImportId(val) {
      if (val) {
        this.getShuxingList(val)
      }
    }
  },
  mounted() {
    this.selfShow = this.show
    this.categoryActiveName = this.obj.name
    this.getCategoryList()
  },
  methods: {
    // 获取同级类目列表
    getCategoryList() {
      this.$api({
        methods: 'get',
        url: '/admin/gcvip/columnData/list',
        params: {
          id: this.obj.parent_id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            let result = res.data.list.filter((item) => {
              return item.id !== this.obj.id && !item.has_children
            })
            this.categoryList = result.map((item) => {
              return {
                key: item.name,
                val: item.id
              }
            })
            if (!this.categoryImportId && this.categoryList.length) {
              this.categoryImportId = this.categoryList[0].val
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg)
        })
    },
    // 获取引用商品属性列表
    getShuxingList() {
      // this.$api({
      //   method: 'get',
      //   url: '/admin/gcvip/midu/list',
      //   params: {
      //     id
      //   }
      // }).then((res) => {
      //   if (res.data.state === 'ok') {
      //     this.tableList = res.data.list
      //     this.$nextTick(() => {
      //       this.$refs.refTable.setAllCheckboxRow(true)
      //     })
      //   } else {
      //     this.$message.error(res.data.msg)
      //   }
      // })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let result = this.$refs.refTable.getCheckboxRecords()
      this.$emit('success', result)
      this.popupCancel()
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
